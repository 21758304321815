import styled from 'styled-components';

export const Page = styled.div`
  padding-bottom: 40px;
`;

export const Wrap = styled.div`
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1260px;
  width: 100%;
`;

export const Button = styled.a`
  background: transparent;
  color: #cb2027;
  font-size: 14px;
  line-height: 19px;
  height: 48px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  padding: 12px 48px;
  border: 2px solid #cb2027;
  border-radius: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  &:hover {
    color: #fff;
    background-color: #cb2027;
  }
`;

export const ButtonPlain = styled(Button)`
  border-color: #fff;
  color: #fff;
  &:hover {
    color: #000;
    background-color: #fff;
  }
`;

export const ButtonInverted = styled(Button)`
  color: #fff;
  background-color: #cb2027;
  &:hover {
    color: #cb2027;
    background-color: transparent;
  }
`;
