import { css } from 'styled-components';
import { mapValues } from 'lodash';

const sizes = {
  tablet: 768,
  PC: 1025,
};

const media = mapValues(
  sizes,
  (size) =>
    (...args) =>
      css`
        @media only screen and (min-width: ${size}px) {
          ${css(...args)}
        }
      `
);

export default media;
