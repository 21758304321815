/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useRef } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useClickAway } from 'react-use';
import { Wrap, Button } from '../../styled';
import { TypeformButtonMain } from '../../components';
import ActiveLink from './ActiveLink';
import {
  Container,
  Inner,
  Navigation,
  Nav,
  NavBtn,
  CloseBtn,
  Ul,
  Li,
  ButtonToggle,
  Dropdown,
  DropdownLi,
} from './styled';

import logo from './images/logo.svg';

const Header = () => {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [isDropdownVisible, setiIsDropdownVisible] = useState(false);

  const showNav = () => {
    setIsNavVisible(true);
  };

  const hideNav = () => {
    setIsNavVisible(false);
  };

  const toggleDropdown = () => {
    setiIsDropdownVisible((value) => !value);
  };

  const hideDropdown = () => {
    setiIsDropdownVisible(false);
  };

  const dropdownRef = useRef(null);
  const navRef = useRef(null);

  useClickAway(dropdownRef, () => {
    hideDropdown();
  });

  useClickAway(navRef, () => {
    hideNav();
  });

  return (
    <Container>
      <Wrap>
        <Inner>
          <Link href="/">
            <Image src={logo} width="141" height="38" alt="EyeFitU home" />
          </Link>
          <NavBtn type="button" onClick={showNav}>
            Menu
          </NavBtn>
          <Navigation ref={navRef} isVisible={isNavVisible}>
            <CloseBtn type="button" onClick={hideNav} />
            <Button as={TypeformButtonMain}>Get more info</Button>
            <Nav>
              <Ul>
                <Li>
                  <ActiveLink href="/how-it-works">How it works</ActiveLink>
                </Li>
                <Li>
                  <ActiveLink href="/partners">Partners</ActiveLink>
                </Li>
                <Li>
                  <ActiveLink href="/success-stories">
                    Success stories
                  </ActiveLink>
                </Li>
                <Li>
                  <ActiveLink href="/foot-scanner">
                    FootScanner™
                  </ActiveLink>
                </Li>
                <Li ref={dropdownRef}>
                  <ButtonToggle
                    type="button"
                    active={isDropdownVisible}
                    onClick={toggleDropdown}
                  >
                    About
                  </ButtonToggle>
                  <Dropdown isVisible={isDropdownVisible}>
                    <ul>
                      <DropdownLi>
                        <ActiveLink href="/our-story">Our story</ActiveLink>
                      </DropdownLi>
                      <DropdownLi>
                        <ActiveLink href="/press">Press</ActiveLink>
                      </DropdownLi>
                      <DropdownLi>
                        <ActiveLink href="/achievements">
                          Achievements
                        </ActiveLink>
                      </DropdownLi>
                      <DropdownLi>
                        <ActiveLink href="/blog">Blog</ActiveLink>
                      </DropdownLi>
                    </ul>
                  </Dropdown>
                </Li>
              </Ul>
            </Nav>
          </Navigation>
        </Inner>
      </Wrap>
    </Container>
  );
};

export default Header;
