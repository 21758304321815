import { ReactNode } from 'react';
import { SliderButton } from '@typeform/embed-react';

enum Typeform {
  Main = 'YFmEQ4',
  Partners = 'SgJujCvV',
}

type TypeformButtonProps = {
  children: ReactNode;
};

export const TypeformButtonMain = (props: TypeformButtonProps) => (
  <SliderButton {...props} id={Typeform.Main} />
);

export const TypeformButtonPartners = (props: TypeformButtonProps) => (
  <SliderButton {...props} id={Typeform.Partners} />
);
