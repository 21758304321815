import { Component } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { initGA, logPageView } from '../../init/google-analytics';
import Header from '../header';
import Footer from '../footer';
import CookieAlert from '../cookie-alert';
import { Container } from './styled';

export default class Layout extends Component {
  componentDidMount() {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();
  }

  render() {
    const { children } = this.props;

    return (
      <Container>
        <Head>
          <title>Size Engine Recommendation Software</title>
          <meta
            name="description"
            content="Size recommendation, fit advisor software for fashion and apparel stores"
          />
          <link rel="shortcut icon" href="/images/favicon.ico" />
          <meta property="og:locale" content="en_US" />
          <meta
            name="google-site-verification"
            content="N132qDv2Z6cft9-1vP4x5AkpmhFbwZQ13zoooDnGwVo"
          />
          <meta
            name="google-site-verification"
            content="jNhor7BMMRvH8vAoBCAd8m9ai9wgFV-D3bRH742MRi8"
          />
          <meta
            name="verification"
            content="0306c88b7d42e8c86d0e9636621b9a3b"
          />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta property="og:type" content="article" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@eyefitu" />
        </Head>
        <Header />
        {children}
        <Footer />
        <CookieAlert />
      </Container>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
