import styled from 'styled-components';
import media from '../../styled/media';

export const Container = styled.div`
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  z-index: 1000000000;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
`;

export const Inner = styled.div`
  text-align: center;
  ${media.tablet`
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  margin: 16px 0;
  a {
    color: inherit;
    text-decoration: underline;
  }
`;

export const Btn = styled.button`
  background: none;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 100px;
  text-transform: uppercase;
  padding: 3px 21px;
  font-size: 12px;
  white-space: nowrap;
  margin: 0 0 16px 0;
  ${media.tablet`
    margin: 0 0 0 24px;
  `}
`;
