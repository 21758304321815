/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/accessible-emoji */
import { Component } from 'react';
import Link from 'next/link';
import { Wrap } from '../../styled';
import { Container, Inner, Text, Btn } from './styled';

export default class CookieAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };
  }

  componentDidMount() {
    if (!JSON.parse(localStorage.getItem('cookie'))) {
      this.setState({
        isVisible: true,
      });
    }
  }

  closeAlert() {
    localStorage.setItem('cookie', true);
    this.setState({
      isVisible: false,
    });
  }

  render() {
    const { isVisible } = this.state;

    return (
      isVisible && (
        <Container>
          <Wrap>
            <Inner>
              <Text>
                By continuing to browse our website, you agree to our{' '}
                <Link href="/cookie-policy">cookie policy</Link>
              </Text>
              <Btn type="button" onClick={() => this.closeAlert()}>
                got it 🍪
              </Btn>
            </Inner>
          </Wrap>
        </Container>
      )
    );
  }
}
