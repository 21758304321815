import ReactGA from 'react-ga';
import config from '../config/google-analytics';

export const initGA = () => {
  ReactGA.initialize(config.trackerID, { debug: false });
};

export const logPageView = () => {
  const page = window.location.pathname;
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

export default ReactGA;
