/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prefer-stateless-function */
import { Component } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { Wrap } from '../../styled';
import {
  Container,
  Inner,
  Cell,
  NavItems,
  NavItem,
  NavItemHighlight,
  SocialLinks,
  SocialLink,
  SwissMadeSoftware,
} from './styled';

import logoGray from './images/logo-gray.svg';
import swissMadeSoftware from './images/swiss-made-software.svg';
import iconFacebook from './images/icon-facebook.svg';
import iconLinkedin from './images/icon-linkedin.svg';
import iconInstagram from './images/icon-instagram.svg';

export default class Footer extends Component {
  render() {
    return (
      <Container>
        <Wrap>
          <Inner>
            <Cell>
              <div>
                <Image
                  src={logoGray}
                  width="134"
                  height="38"
                  alt="EyeFitU logo"
                />
                <p>Zürich, Switzerland</p>
                <p>
                  <a href="mailto:info@eyefitu.com">info@eyefitu.com</a>
                </p>
                <SwissMadeSoftware
                  src={swissMadeSoftware}
                  width="97"
                  height="28"
                  alt="Swiss Made Software logo"
                />
              </div>
            </Cell>
            <Cell>
              <nav>
                <NavItems>
                  <NavItemHighlight>
                    <Link href="/how-it-works">How it works</Link>
                  </NavItemHighlight>
                  <NavItemHighlight>
                    <Link href="/partners">Partners</Link>
                  </NavItemHighlight>
                  <NavItemHighlight>
                    <Link href="/success-stories">Success Stories</Link>
                  </NavItemHighlight>
                  <NavItemHighlight>
                    <Link href="/foot-scanner">FootScanner™</Link>
                  </NavItemHighlight>
                  <NavItemHighlight>
                    <Link href="/blog">Blog</Link>
                  </NavItemHighlight>
                </NavItems>
              </nav>
            </Cell>
            <Cell>
              <nav>
                <NavItems>
                  <NavItem>
                    <Link href="/imprint">Impressum</Link>
                  </NavItem>
                  <NavItem>
                    <Link href="/terms">Terms & conditions</Link>
                  </NavItem>
                  <NavItem>
                    <Link href="/privacy">Privacy policy</Link>
                  </NavItem>
                  <NavItem>
                    <Link href="/cookie-policy">Cookie policy</Link>
                  </NavItem>
                </NavItems>
              </nav>
            </Cell>
            <Cell>
              <SocialLinks>
                <SocialLink>
                  <a
                    href="https://www.facebook.com/Eyefitu?ref=aymt_homepage_panel"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      src={iconFacebook}
                      width="32"
                      height="32"
                      alt="Facebook icon"
                    />
                  </a>
                </SocialLink>
                <SocialLink>
                  <a
                    href="https://www.linkedin.com/company/eyefitu-ag"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      src={iconLinkedin}
                      width="32"
                      height="32"
                      alt="Linkedin icon"
                    />
                  </a>
                </SocialLink>
                <SocialLink>
                  <a
                    href="https://instagram.com/eyefituofficial/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      src={iconInstagram}
                      width="32"
                      height="32"
                      alt="Instagram icon"
                    />
                  </a>
                </SocialLink>
              </SocialLinks>
            </Cell>
          </Inner>
        </Wrap>
      </Container>
    );
  }
}
