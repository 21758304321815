import styled from 'styled-components';
import Image from 'next/image';
import media from '../../styled/media';

export const Container = styled.footer`
  margin-top: auto;
  background: #d9d9d9;
  font-size: 12px;
  line-height: 21px;
  color: #5c5c5c;
  padding: 40px 0;
  a {
    color: inherit;
    text-decoration: none;
  }
  p {
    margin: 9px 0;
  }
  ${media.tablet`
    padding: 48px 0;
  `}
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  ${media.tablet`
    flex-direction: row;
  `}
`;

export const Cell = styled.div`
  ${media.tablet`
    width: 25%;
    padding: 0;
  `}
`;

export const NavItems = styled.ul`
  line-height: 16px;
`;

export const NavItem = styled.li`
  margin: 14px 0;
`;

export const NavItemHighlight = styled(NavItem)`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  ${media.tablet`
    font-size: inherit;
    line-height: inherit;
  `}
`;

export const SocialLinks = styled.ul`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  ${media.tablet`
    margin: 0;
  `}
`;

export const SocialLink = styled.li`
  margin: 0 16px;
`;

export const SwissMadeSoftware = styled(Image)`
  display: block;
  margin: 16px 0;
`;
