/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';
import Link from 'next/link';
import { A } from './styled';

const ActiveLink = (props) => {
  const { router, href, children } = props;

  return (
    <Link href={href} passHref legacyBehavior>
      <A active={router.asPath === href}>{children}</A>
    </Link>
  );
};

ActiveLink.propTypes = {
  router: PropTypes.shape({
    asPath: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
};

export default withRouter(ActiveLink);
