import styled from 'styled-components';
import media from '../../styled/media';

export const Container = styled.header`
  position: sticky;
  background: #fff;
  z-index: 2;
  top: 0;
`;

export const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  height: 90px;
  align-items: center;
`;

export const Navigation = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 1;
  text-align: center;
  padding: 60px 0;
  ${media.tablet`
    position: static;
    padding: 0;
    display: flex;
    align-items: center;
    flex: 1;
  `}
`;

export const Nav = styled.nav`
  text-align: left;
  ${media.tablet`
    order: -1;
    flex: 1;
  `}
`;

export const NavBtn = styled.button`
  font-size: 16px;
  font-weight: 600;
  color: #4a4a4a;
  text-transform: uppercase;
  padding: 0;
  background: none;
  border: none;
  ${media.tablet`
    display: none;
  `}
`;

export const CloseBtn = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background: url('/images/retailers/icons/close.svg') no-repeat center;
  border: none;
  width: 42px;
  height: 42px;
  ${media.tablet`
    display: none;
  `}
`;

export const Ul = styled.ul`
  ${media.tablet`
    display: flex;
    justify-content: space-around;
    justify-content: space-evenly;
  `}
`;

export const Li = styled.li`
  position: relative;
  font-size: 18px;
  line-height: 1;
  margin-top: 32px;
  color: #4a4a4a;
  padding: 0 20px;
  ${media.tablet`
    padding: 0;
    margin: 0;
    font-size: 14px;
  `}
`;

export const A = styled.a<{ active: boolean }>`
  text-decoration: none;
  color: ${({ active }) => (active ? '#CB2027' : 'inherit')};
  transition: all 0.2s ease;
  &:hover {
    color: #cb2027;
  }
`;

export const ButtonToggle = styled.button<{ active: boolean }>`
  border: none;
  background: none;
  padding: 0;
  color: inherit;
  transition: all 0.2s ease;
  :hover {
    color: #cb2027;
  }
  :after {
    position: relative;
    top: -1px;
    margin-left: 8px;
    content: '';
    display: inline-block;
    width: 13px;
    height: 7px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('/images/retailers/icons/dropdown-arrow.svg');
    transform: rotate(${({ active }) => (active ? '0' : '180deg')});
    ${media.tablet`
      width: 10px;
      height: 6px;
      background-image: url('/images/retailers/icons/dropdown-arrow-small.svg');
    `}
  }
`;

export const Dropdown = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  ${media.tablet`
    position: absolute;
    top: 40px;
    left: 50%;
    margin-left: -88px;
    width: 166px;
    background: #fff;
    box-shadow: 0px 2px 12px rgba(55, 0, 0, .25);
    border-radius: 10px;
    :after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border: 8px solid #fff;
      border-color: #fff #fff transparent transparent;
      box-shadow: 6px -6px 12px rgba(55, 0, 0, .1);
      position: absolute;
      transform-origin: 0 0;
      transform: rotate(-45deg) translateX(-50%) translateY(-50%);
      top: 0;
      left: 50%;
    }
  `}
`;

export const DropdownLi = styled.li`
  padding-left: 20px;
  color: #686868;
  margin: 24px 0;
  ${media.tablet`
    margin: 16px 0;
  `}
`;
